(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
// Add all the core site events here
module.exports = {
  PAGE_VIEW: 'view',
  EXIT_CLICK: 'exit.click',
  DOWNLOAD: 'download',
  APPLY: 'apply',
  SUBMIT: 'submit',
  LOGIN: 'login',
  REGISTER: 'register',
  CLICK: 'click',
  PLAY: 'play',
  PAUSE: 'pause',
  RESUME: 'resume',
  END: 'end',
  PROGRESS: 'progress',
  DISPLAY: 'display',
  CLOSE: 'close',
  ACCEPT: 'accept',
  RATING: 'rating',
  WEBLINK: 'weblink',
  BUNDLE: 'bundle',
  REVIEW: 'review',
  REPLY: 'reply',
  BUY: 'buy',
  REGISTER_PROPERTY: 'register_property',
  LOGIN_FAIL: 'login-fail',
  REGISTER_FAIL: 'register-fail',
  LOGIN_SUCCESS: 'login-success',
  REGISTER_SUCCESS: 'register-success',
  IMPRESSION: 'impression',
  ERROR: 'error'
};

},{}],2:[function(require,module,exports){
var et = require('./event-types');

(function(w) {
  w.TES = w.TES || {};
  w.TES.mz = w._mz = {};
  w.TES.mz.events = w._mze = et;
  w._mz_def = [];
  var fakeMzEmit = w._mz.emit = function(t, d, n) {
    if (w._mz.emit === fakeMzEmit) {
      w._mz_def.push({ f: 'emit', t: t, d: d, n: n});
    } else {
      w._mz.emit(t, d, n);
    }
  };
  var fakeMzUpdatePageMetadata = w._mz.updatePageMetadata = function(npm) {
    if (w._mz.updatePageMetadata === fakeMzUpdatePageMetadata) {
      w._mz_def.push({ f: 'updatePageMetadata', npm: npm});
    } else {
      w._mz.updatePageMetadata(npm);
    }
  }
}(window));

},{"./event-types":1}]},{},[2]);
